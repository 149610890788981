import React from "react";

const linkedinlogo = () => {
  return (
    <svg
      fill='currentColor'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='0'
      className='w-5 h-5'
      viewBox='0 0 24 24'
    >
      <path d='M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z' />
      <circle cx='4' cy='4' r='2' />
    </svg>
  );
};

export default linkedinlogo;
