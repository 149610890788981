import React, { useEffect, useState } from "react";
import { config } from "../config";
import { LeftArrow, RightArrow } from "../components/assets/svg/LRarrows";
import { StlViewer } from "react-stl-viewer";
import LoadingSVG from "../components/assets/svg/loading";
import SetErrorOnCode from "../components/utilities/errorMessageFromCode";
import ErrorDisplay from "../components/errorDisplay";
import SecondaryButton from "../components/buttons/SecondaryButton";

const Item = () => {
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [imageId, setImageId] = useState(0);
  const [StlId, setStlId] = useState(0);
  const [viewMode] = useState("images");
  const id = window.location.pathname.split("/")[2];

  useEffect(() => {
    setIsError(false);
    setError(null);
    if (viewMode === "images") {
      fetchImages();
    } else if (viewMode === "3D") {
      fetchStl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, viewMode]);

  const fetchImages = () => {
    setData(null);
    fetch(`${config.addresses.api}/catalog?id=${id}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setIsError(true);
          setError(SetErrorOnCode(response.status));
        }
      })
      .then((json) => setData(json))
      .catch((error) => {
        setIsError(true);
        setError("Failed to load catalog");
        setError(SetErrorOnCode(error));
      });
  };

  const fetchStl = async () => {
    setData(null);
    await fetch(`${config.addresses.api}/stl?id=${id}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        setIsError(true);
        setError(SetErrorOnCode(response.status));
      })
      .then((json) => setData(json))
      .catch((error) => {
        setIsError(true);
        setError(SetErrorOnCode(error));
      });
  };

  const postPreOrder = async () => {
    if (!email) {
      console.log("No email entered");
      return;
    }
    try {
      const response = await fetch(`${config.addresses.api}/preorder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          id: id,
          material: "PLA",
          color: "White",
          quantity: 1,
        }),
      });
      if (!response.ok) {
        setIsError(true);
        setError(SetErrorOnCode(response.status));
      }
      alert("Pre-order successful");
      window.location.href = "/";
    } catch (error) {
      setIsError(true);
      setError(SetErrorOnCode(error));
    }
  };

  return (
    <>
      {data && !isError ? (
        <div className='flex flex-col sm:flex-row justify-center p-1 h-full w-full'>
          <div className='flex flex-col justify-center items-center w-full sm:w-1/2 mr-20'>
            <div className='relative flex flex-row justify-evenly items-center w-full h-auto'>
              {data.images
                ? data.images.length > 1 && (
                    <button
                      className='transition-all hover:opacity-50 p-2 m-2 rounded-full shadow h-20 w-20 focus:outline-none focus:shadow-outline'
                      onClick={() =>
                        setImageId(
                          (imageId - 1 + data.images.length) %
                            data.images.length
                        )
                      }
                    >
                      <LeftArrow />
                    </button>
                  )
                : data.stlFiles &&
                  data.stlFiles.length > 1 && (
                    <button
                      className='transition-all hover:opacity-50 p-2 m-2 rounded-full shadow h-20 w-20 focus:outline-none focus:shadow-outline'
                      onClick={() =>
                        setStlId(
                          (StlId - 1 + data.stlFiles.length) %
                            data.stlFiles.length
                        )
                      }
                    >
                      <LeftArrow />
                    </button>
                  )}
              {viewMode === "images" ? (
                <img
                  className='transition-all delay-150 flex h-96 w-96 rounded-lg overflow-hidden object-cover m-2'
                  src={
                    data.images[imageId] ??
                    config.addresses.assets + "/png/noimg.png"
                  }
                  alt=''
                />
              ) : data.stlFiles && data.stlFiles.length > 1 ? (
                <div className='flex justify-center items-center h-96 w-96 rounded-lg overflow-hidden outline outline-1 outline-black dark:outline-white hover:cursor-grab active:cursor-grabbing'>
                  <StlViewer
                    style={{ width: "100%", height: "100%" }}
                    key={data.stlFiles[StlId]}
                    url={data.stlFiles[StlId]}
                    orbitControls={true}
                  />
                </div>
              ) : (
                <img
                  className='transition-all delay-150 flex h-96 w-96 rounded-lg overflow-hidden object-cover m-2'
                  src={config.addresses.assets + "/png/noimg.png"}
                  alt=''
                />
              )}

              {data.images
                ? data.images.length > 1 && (
                    <button
                      className='transition-all hover:opacity-50 p-2 m-2 rounded-full shadow h-20 w-20 focus:outline-none focus:shadow-outline'
                      onClick={() =>
                        setImageId((imageId + 1) % data.images.length)
                      }
                    >
                      <RightArrow />
                    </button>
                  )
                : data.stlFiles &&
                  data.stlFiles.length > 1 && (
                    <button
                      className='transition-all hover:opacity-50 p-2 m-2 rounded-full shadow h-20 w-20 focus:outline-none focus:shadow-outline'
                      onClick={() =>
                        setStlId((StlId + 1) % data.stlFiles.length)
                      }
                    >
                      <RightArrow />
                    </button>
                  )}
            </div>
            <div className='flex flex-row flex-nowrap justify-center overflow-scroll scroll-smooth no-scrollbar w-full'>
              {data.images
                ? data.images.map((img, index) => (
                    <img
                      key={index}
                      className='transition-all ease-in-out h-20 w-auto rounded-lg hover:opacity-50 hover:cursor-pointer object-cover m-2'
                      src={img}
                      alt=''
                      onClick={() => setImageId(index)}
                    />
                  ))
                : data.stlFiles &&
                  data.stlFiles.map((stl, index) => (
                    <button
                      onClick={() => setStlId(index)}
                      className='rounded-lg overflow-hidden m-2 h-20 w-20 transition-all outline outline-1 outline-black dark:outline-white hover:opacity-50 hover:cursor-pointer'
                    >
                      <StlViewer
                        style={{ width: "100%", height: "100%" }}
                        url={stl}
                        key={index}
                        orbitControls={false}
                      />
                    </button>
                  ))}
            </div>
          </div>
          <div className='flex flex-col justify-evenly items-center w-full sm:w-1/3'>
            <h1 className='title-font text-xl font-bold text-Ctext dark:text-CtextDark mb-3'>
              {data.name}
            </h1>
            <p className='title-font text-lg font-light text-Ctext dark:text-CtextDark mb-3'>
              {data.description}
            </p>
            <input
              className='w-2/3 sm:w-1/2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'
              type='email'
              pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
              required
              onKeyDown={(e) => e.key === "Enter" && postPreOrder()}
            />
            <button
              className='m-1 text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-content px-5 py-2.5 my-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
              onClick={postPreOrder}
            >
              Pre-Order
            </button>
            <SecondaryButton
              text='Back to catalog'
              className='m-1'
              onClick={() => window.location.replace("/catalog")}
            />
          </div>
        </div>
      ) : !data && !isError ? (
        <LoadingSVG />
      ) : (
        isError && (
          <ErrorDisplay
            error={error}
            retry={viewMode === "images" ? fetchImages : fetchStl}
          />
        )
      )}
    </>
  );
};

export default Item;
