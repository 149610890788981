import React from "react";
import { config } from "../config";
import FacebookLogo from "../components/assets/svg/social/facebooklogo";
import InstagramLogo from "../components/assets/svg/social/instagramlogo";
import LinkedInLogo from "../components/assets/svg/social/linkedinlogo";
import TwitterLogo from "../components/assets/svg/social/twitterlogo";

const footer = () => {
  return (
    <footer className='text-Ctext dark:text-CtextDark body-font z-10'>
      <div className='mt-1 flex-grow border-t border-gray-400'></div>
      <div className='container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col'>
        <a
          className='flex title-font text-xl font-semibold items-center justify-center'
          href='/'
        >
          <img
            src={`${config.addresses.assets}/png/logo.png`}
            alt='Goldenlab logo'
            className='w-10 h-10'
          />
          <span className='ml-3 text-xl'>Goldenlab</span>
        </a>
        <p className='text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4'>
          Leave your feedback at this address:&nbsp;
          <a href='mailto:feedback@goldenlab.dev' className='text-CtextLink'>
            feedback@goldenlab.dev
          </a>
          <br />
          <br />A french project by&nbsp;
          <a
            href='https://www.linkedin.com/in/léandre-colard'
            className='hover:underline text-CtextLink'
          >
            Léandre Colard&nbsp;
          </a>
          and&nbsp;
          <a
            href='https://www.linkedin.com/in/elman-ismailov'
            className='hover:underline text-CtextLink'
          >
            Elman Ismailov
          </a>
        </p>
        <span className='inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start'>
          <a className='text-gray-500' href='https://www.facebook.com/'>
            <FacebookLogo />
          </a>
          <a
            className='ml-3 text-gray-500'
            href='https://twitter.com/GoldenlabD39694'
          >
            <TwitterLogo />
          </a>
          <a
            className='ml-3 text-gray-500'
            href='https://www.instagram.com/goldenlab.dev/'
          >
            <InstagramLogo />
          </a>
          <a className='ml-3 text-gray-500' href='https://linkedin.com'>
            <LinkedInLogo />
          </a>
        </span>
      </div>
    </footer>
  );
};

export default footer;
