import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { config } from "../config";
import CatalogCard from "../components/cards/catalogCard";
import LoadingSVG from "../components/assets/svg/loading";
import { PaginationButton } from "../components/buttons/paginationButton";
import ErrorDisplay from "../components/errorDisplay";
import SetErrorOnCode from "../components/utilities/errorMessageFromCode";
import { LeftArrow, RightArrow } from "../components/assets/svg/LRarrows";

const Catalog = () => {
  const [location] = useSearchParams();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isError, setIsError] = useState(false);

  function fetchCatalog() {
    setError(null);
    setIsError(false);
    fetch(`${config.addresses.api}/catalog?page=${location.get("page")}`)
      .then((response) => {
        if (response.ok) {
          console.log(localStorage.getItem("customToken"));
          return response.json();
        } else {
          setIsError(true);
          setError(SetErrorOnCode(response.status));
        }
      })
      .then((json) => {
        setData(json);
      })
      .catch((error) => {
        setIsError(true);
        SetErrorOnCode(error);
      });
  }

  useEffect(() => {
    setIsError(false);
    setError(null);
    if (!location.has("page")) {
      location.set("page", 1);
    }
    fetchCatalog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {data && !isError ? (
        <>
          <div className="flex flex-row flex-wrap justify-evenly">
            {data.items.map((item) => (
              <CatalogCard
                className="relative"
                img={item.images}
                title={item.name}
                description={item.description}
                key={item.id}
                link={"/catalog/" + item.id}
              />
            ))}
          </div>
          <div>
            {data && data.items > 0 ? (
              <>
                <div className="text-center mb-10">No more items to show</div>
                <PaginationButton text="Previous" page={-1} />
              </>
            ) : (
              <div className="flex flex-row justify-evenly">
                {location.get("page") > 1 ? (
                  <PaginationButton text={<LeftArrow width="32" />} page={-1} />
                ) : (
                  <PaginationButton
                    text={<LeftArrow width="32" />}
                    className="opacity-20"
                    onClick={() => {}}
                    page={-1}
                  />
                )}
                {/* 1, 2, 3, 4, 5 */}
                {location.get("page") < data.maxPage ? (
                  <PaginationButton text={<RightArrow width="32" />} page={1} />
                ) : (
                  <PaginationButton
                    text={<RightArrow width="32" />}
                    className="opacity-20"
                    onClick={() => {}}
                    page={1}
                  />
                )}
              </div>
            )}
          </div>
        </>
      ) : !data && !isError ? (
        <LoadingSVG />
      ) : (
        isError && <ErrorDisplay error={error} retry={fetchCatalog} />
      )}
    </>
  );
};

export default Catalog;
