import React from "react";
import Navbar from "./navbar";

const header = () => {
  return (
    <header className='w-full sticky z-10 top-0 p-0 m-0 h-content bg-Cbackground dark:bg-CbackgroundDark dark:text-CtextDark text-Ctext h-content'>
      <Navbar />
      <div className='relative flex items-center'>
        <div className='mt-1 flex-grow border-t border-gray-400'></div>
      </div>
    </header>
  );
};

export default header;

// link to an example:
// https://www.realtimecolors.com/?colors=dddbff-030024-766cfe-a2017a-fd087e&fonts=Poppins-Poppins
