import * as React from "react";
const darkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;

export const RightArrow = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' {...props}>
    <title>{"Artboard-34"}</title>
    <path
      d='m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z'
      data-name='Right'
      style={darkMode ? { fill: "#FFFFFF" } : { fill: "#000000" }}
    />
  </svg>
);

export const LeftArrow = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' {...props}>
    <title>{"Artboard-35"}</title>
    <path
      d='M24 12.001H2.914l5.294-5.295-.707-.707L1 12.501l6.5 6.5.707-.707-5.293-5.293H24v-1z'
      data-name='Left'
      style={darkMode ? { fill: "#FFFFFF" } : { fill: "#000000" }}
    />
  </svg>
);
