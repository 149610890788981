import React from "react";
import { useSearchParams } from "react-router-dom";

export function PaginationButton(props) {
  const { className, ...buttonProps } = props;
  const [location, setLocation] = useSearchParams();
  const defaultClassname =
    "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800";
  return (
    <button
      className={[defaultClassname, className].filter(Boolean).join(" ")}
      onClick={() => {
        const currentPage = location.get("page")
          ? parseInt(location.get("page"))
          : 1;
        const nextPage = currentPage + props.page ?? 1;
        location.set("page", nextPage.toString());
        setLocation(location);
      }}
      {...buttonProps}
    >
      {props.text}
    </button>
  );
}
