import React from "react";
import SubmitButton from "../components/buttons/submitButton";

const Test = () => {
  return (
    <div>
      <SubmitButton text='test' className='opacity-30' onClick={() => {}} />
    </div>
  );
};

export default Test;
