import React from "react";

const submit = (props) => {
  const { className, ...buttonProps } = props;
  const defaultClassName =
    "transition text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-max px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800";
  return (
    <button
      type='submit'
      className={[defaultClassName, props.className].join(" ")}
      {...buttonProps}
    >
      {props.text}
    </button>
  );
};

export default submit;
