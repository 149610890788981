import React from "react";

const catalogButton = (props) => {
  const { className, ...buttonProps } = props;
  const defaultClassName =
    "text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-3xl w-full sm:w-auto px-5 py-2.5 m-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800";

  return (
    <a
      href={props.link}
      className={[defaultClassName, className].join(" ")}
      {...buttonProps}
    >
      {props.text}
    </a>
  );
};

export default catalogButton;
