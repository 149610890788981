import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { config } from "./config";
import "./index.css";
import Header from "./components/header";
import Footer from "./components/footer";
import Homepage from "./pages/Homepage";
import Catalog from "./pages/Catalog";
import Login from "./components/LoginRegister/Login";
import Register from "./components/LoginRegister/Register";
import Item from "./pages/Item";
import NIY from "./pages/NotImplementedYet";
import Test from "./pages/Test";
import UploadFile from "./pages/UploadFile";
import ResetPassword from "./components/LoginRegister/ResetPassword";
import EmailPasswordReset from "./components/LoginRegister/EmailPasswordReset";
import ProfilePage from "./pages/Profile";

// import TipsNTricks from "./pages/TipsNTricks";
// import ContactUs from "./pages/ContactUs";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <div className='flex flex-col min-h-screen justify-between h-full w-full bg-Cbackground dark:bg-CbackgroundDark text-Ctext dark:text-CtextDark'>
      <Header />
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='catalog' element={<Catalog />} />
        <Route path='catalog/:id' element={<Item />} />
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='ai-generator' element={<NIY />} />
        <Route path='materials' element={<NIY />} />
        <Route path='about-us' element={<NIY />} />
        <Route path='contact-us' element={<NIY />} />
        <Route path='upload' element={<UploadFile />} />
        <Route path='reset-password' element={<ResetPassword />} />
        <Route path='forgot-password' element={<EmailPasswordReset />} />
        <Route path='profile' element={<ProfilePage />} />

        {/* <Route path='contact-us' element={<NIY />} />
        <Route path='tips-n-tricks' element={<NIY />} /> */}
        <Route path='testrafce' element={<Test />} />
        <Route path='*' element={<NIY />} />
        <Route path='assets'>
          <Route
            path='facebookLogo'
            element={`${config.addresses.assets}/png/social/facebooklogo.png`}
          />
          <Route
            path='instagramLogo'
            element={`${config.addresses.assets}/png/social/instagramlogo.png`}
          />
          <Route
            path='linkedinLogo'
            element={`${config.addresses.assets}/png/social/linkedinlogo.png`}
          />
          <Route
            path='twitterLogo'
            element={`${config.addresses.assets}/png/social/twitterlogo.png`}
          />
        </Route>
      </Routes>
      <Footer />
    </div>
  </Router>
);
