import React from "react";

const SecondaryButton = (props) => {
  const { className, ...buttonProps } = props;
  const defaultClassName = "";
  return (
    <button
      type='submit'
      className={[defaultClassName, className].join(" ")}
      {...buttonProps}
    >
      {props.text}
    </button>
  );
};

export default SecondaryButton;
