import React from "react";
import SubmitButton from "./buttons/submitButton";
import SecondaryButton from "./buttons/SecondaryButton";

const errorDisplay = (props) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="pb-10">{props.error}</div>
      <SubmitButton onClick={props.retry} text="Reload" />
      <SecondaryButton
        onClick={() => (window.location.href = "/")}
        text={"Back to Home"}
      />
    </div>
  );
};

export default errorDisplay;
