import React from "react";
import { CardBody, CardContainer, CardItem } from "../ui/3d-card.tsx";
import { config } from "../../config.jsx";

const homecard = (props) => {
  return (
    <a href={props.link} alt='Card'>
      <CardContainer className='mx-1'>
        <CardBody className='relative flex flex-col justify-between w-[23rem] sm:w-[25rem] h-auto rounded-xl p-6 border bg-gray-50 group/card dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1]'>
          <CardItem translateZ='60' className='w-full'>
            <img
              src={props.img[0] ?? config.addresses.assets + "/png/noimg.png"}
              height='1000'
              width='1000'
              className='h-60 w-full object-cover rounded-xl group-hover/card:shadow-xl'
              alt='thumbnail'
            />
          </CardItem>{" "}
          <div className='h-[50%] flex flex-col justify-center items-center'>
            <CardItem
              translateZ='50'
              className='text-xl font-bold text-neutral-600 dark:text-white '
            >
              {props.title}
            </CardItem>
            <CardItem
              as='p'
              translateZ='60'
              className='text-neutral-500 text-sm max-w-sm mt-2 dark:text-neutral-300 h-10 overflow-hidden'
            >
              {props.description}
            </CardItem>
          </div>
        </CardBody>
      </CardContainer>
    </a>
  );
};

export default homecard;

/*


    <div className='h-[35vh] sm:h-[400px] p-2 w-1/2 sm:w-1/4'>
      <div className='h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden'>
        <a href={props.link}>
          <img
            className='sm:h-48 w-full object-cover object-center'
            src={props.img}
            alt='Card'
          />
        </a>
        <div className='p-6'>
          <h1 className='title-font text-lg font-medium mb-3 text-Ctext dark:text-CtextDark'>
            {props.title}
          </h1>
          <p>{props.description}</p>
          <div className='flex items-center flex-wrap '>
            <a
              title=''
              href={props.link}
              className='text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0 hover:underline'
            />
          </div>
        </div>
      </div>
    </div>

*/
