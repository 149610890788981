import React, { useEffect, useState } from "react";
import { config } from "../../config";
import SecondaryButton from "../buttons/SecondaryButton";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [popup, setPopup] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${config.addresses.api}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: username, password: password }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          return setPopup("Please verify your email");
        } else {
          return setPopup("Login failed. Please try again later.");
        }
      })
      .then((json) => {
        localStorage.setItem("customToken", json.customToken);
        alert("Login Successful");
        window.location.replace("/");
      })
      .catch(() => {
        setUsername("");
        setPassword("");
      });
  };

  useEffect(() => {
    if (popup) {
      alert(popup);
      setPopup();
    }
  }, [popup]);

  return (
    <div className='w-1/2 sm:w-1/4 h-max mx-auto my-10'>
      <form className='space-y-6' onSubmit={handleSubmit}>
        <div className='flex flex-col justify-center gap-6 mb-6 md:grid-cols-2'>
          <div className='mb-4'>
            <label className='block mb-2 text-sm font-bold' htmlFor='username'>
              Username
            </label>
            <input
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'
              id='username'
              type='text'
              placeholder='Username'
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className='mb-6'>
            <label htmlFor='password' className='block mb-2 text-sm font-bold'>
              Password
            </label>
            <input
              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'
              id='password'
              type='password'
              placeholder='******************'
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className='flex flex-col items-center justify-center gap-2'>
          <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
            Login
          </button>

          <SecondaryButton
            text='Forgot Password'
            onClick={() => (window.location.href = "/forgot-password")}
          />
        </div>
      </form>
    </div>
  );
};

export default Login;
