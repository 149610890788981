import React, { useState } from "react";
import { config } from "../../config";
import SubmitButton from "../buttons/submitButton";
import SecondaryButton from "../buttons/SecondaryButton";

const ResetPassword = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [password, setPassword] = useState("");

  function handleSubmit() {
    if (!urlParams.get("token")) {
      alert("Invalid reset link");
    }
    if (!password || password.length < 8) {
      alert("Password must be at least 8 characters long");
      return;
    }
    fetch(`${config.addresses.api}/reset-password`, {
      method: "POST",
      body: JSON.stringify({
        token: urlParams.get("token"),
        newPassword: password,
      }),
    }).then((response) => {
      if (response.ok) {
        alert("Password reset successful");
        window.location.replace("/login");
      } else if (response.status === 400) {
        alert("Invalid reset link");
      } else {
        alert("Password reset failed. Please try again later.");
      }
    });
  }

  return (
    <div className='w-1/2 sm:w-1/4 h-max mx-auto my-10'>
      <label className='block mb-2 text-sm font-bold' htmlFor='username'>
        Reset Password
      </label>
      <form className='flex flex-col justify-center items-center gap-5'>
        <input
          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'
          type='password'
          id='newPassword'
          placeholder='New Password'
          required
        />
        <input
          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'
          type='password'
          placeholder='Confirm Password'
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <SubmitButton text='Reset Password' onClick={handleSubmit} />
        <SecondaryButton
          text='Resend reset link'
          onClick={() => (window.location.href = "/email-reset-password")}
        />
      </form>
    </div>
  );
};

export default ResetPassword;
