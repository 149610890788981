import React, { useRef } from "react";
import Homecard from "../components/cards/homepageCard";
import HomePresentation from "../components/homePresentation";
import { config } from "../config";

const Homepage = () => {
  const div = useRef(null);
  return (
    <>
      <div className='flex flex-col justify-between' ref={div}>
        <HomePresentation />
        {/* <div className='mt-10'>
          THIS WEBSITE IS STILL WORK IN PROGRESS, IF YOU'RE INTERESTED COME
          CHECK
          <br />
          OUR SOCIAL NETWORKS AT THE BOTTOM OF THE PAGE
          <br /> + <br />
          GIVE YOUR FEEDBACK AT THIS ADDRESS:
          <br />
        </div>
        <div className='flex justify-center'>
          <button
            className='flex justify- w-max'
            onClick={() =>
              div.current.scrollIntoView({ behavior: "smooth", block: "end" })
            }
          >
            feedback@goldenlab.dev
          </button>
        </div> */}
      </div>
      <div className='flex flex-row flex-wrap justify-center'>
        <Homecard
          img={[config.addresses.assets + "/png/homecards/catalog.webp"]}
          title='Catalog'
          description='Come check our catalog of 3D printed products'
          link='catalog'
        />
        <Homecard
          img={[config.addresses.assets + "/png/homecards/ai.webp"]}
          title='AI generator'
          description='Coming soon, generate your own 3D models with AI!'
          link='ai-generator'
        />
        {/* <Homecard
          img={[config.addresses.assets + "/png/homecards/materials.webp"]}
          title='Materials'
          description='Discover the materials we use for our 3D printing'
          link='materials'
        /> */}
      </div>
    </>
  );
};

export default Homepage;
